@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;400;500&display=swap");

html {
  scroll-behavior: none;
}
html,
body {
  width: 100%;
  min-height: 100%;
}
body {
  font-family: "Albert Sans", sans-serif;
  /* cursor: url("/public/icons/cursor.svg"), auto; */
}
p {
  margin-bottom: 0px;
}
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

:root {
  --black: #1c1b1b;
  --yellow900: #e8ae1b;
  --yellow800: #c9981a;
  --white: #ffffff;
  --gray: #bfbfbf;
  --grayLight: #f4f4f4;
  --blackLight: #292929;
}
.color-black {
  color: var(--black);
}
.color-blackLight {
  color: var(--blackLight);
}
.bg-colorGrey {
  background-color: white;
}
.bg-blackLight {
  background-color: var(--blackLight);
}
.color-white {
  color: var(--white) !important;
}
.span-color {
  color: var(--yellow900);
}
.mt-50 {
  margin-top: 50px;
}
.main-btn {
  background-color: var(--yellow900);
  padding: 15px 23px;
  text-align: center;
  font-size: 16px;
  letter-spacing: -0.4px;
  font-weight: 600;
  width: max-content;
  display: flex;
  transition: 0.3s;
  color: var(--black);
}
.main-btn-img {
  margin-left: 13px;
  transition: 0.3s;
}
.width-100 {
  width: 100%;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
/* .main-btn:hover {
  cursor: url("/public/icons/cursor-hover.svg"), auto;
} */
.main-btn:hover .main-btn-img {
  margin-left: 20px;
}
.section-padding-normal {
  padding: 120px 0px;
}
.section-padding-top {
  padding-top: 120px;
}
.align-items-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-img {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.align-items-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.position-relative {
  position: relative;
}
.no-overflow {
  overflow: hidden;
}

/* /
/
/
/
/
/
/
/
ROOT CSS
/
/
/
/
/
/
/
/ */
.loader-main {
  background-color: #1c1c1c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
.loading {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  transform: rotate(45deg);
}
.loading .loading-dots {
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 100%;
  float: left;
  margin-bottom: 12px;
  animation: scaleDot 2s ease infinite;
}
.loading .loading-dots:not(:nth-child(4n + 4)) {
  margin-right: 12px;
}
.loading .loading-dots:nth-child(1) {
  animation-delay: 0;
}
.loading .loading-dots:nth-child(2),
.loading .loading-dots:nth-child(5) {
  animation-delay: 0.1s;
}
.loading .loading-dots:nth-child(3),
.loading .loading-dots:nth-child(6),
.loading .loading-dots:nth-child(9) {
  animation-delay: 0.2s;
}
.loading .loading-dots:nth-child(4),
.loading .loading-dots:nth-child(7),
.loading .loading-dots:nth-child(10),
.loading .loading-dots:nth-child(13) {
  animation-delay: 0.3s;
}
.loading .loading-dots:nth-child(8),
.loading .loading-dots:nth-child(11),
.loading .loading-dots:nth-child(14) {
  animation-delay: 0.4s;
}
.loading .loading-dots:nth-child(12),
.loading .loading-dots:nth-child(15) {
  animation-delay: 0.5s;
}
.loading .loading-dots:nth-child(16) {
  animation-delay: 0.6s;
}
@-moz-keyframes scaleDot {
  40% {
    transform: scale(1.3) translate(-2px, -2px);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes scaleDot {
  40% {
    transform: scale(1.3) translate(-2px, -2px);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes scaleDot {
  40% {
    transform: scale(1.3) translate(-2px, -2px);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scaleDot {
  40% {
    transform: scale(1.3) translate(-2px, -2px);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.align-row-banner {
  display: flex;
  align-items: center;
  padding-top: 100px;
}
.header {
  position: fixed;
  width: 100%;
  padding: 0px 0px;
  z-index: 999;
}
.navbar-main {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar-logo {
  height: 40px;
  width: 40px;
}
.navbar-links {
  display: flex;
  align-items: center;
  gap: 20px;
}
.navbar-links .link-item {
  font-size: 16px;
  /* color: var(--black); */
  width: max-content;
  font-weight: 600;
  /* margin: 0px 10px; */
  letter-spacing: -0.3px;
}
.active-link-border {
  border-bottom: 2px solid var(--yellow900) !important;
}
.side-drawer {
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 70%;
  max-width: 400px;
  z-index: 2000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out 0s;
}
.side-drawer.open {
  transform: translateX(0px);
  box-shadow: rgb(0 0 0 / 43%) 2px 0px 20px;
}
.side-drawer ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}
.side-drawer li {
  margin: 10px 0px;
}
.side-drawer .side-drawer-links {
  text-decoration: none;
  transition: 0.3s;
}
.side-drawer:hover .side-drawer-links {
  opacity: 0.5;
}
.side-drawer .side-drawer-links:hover {
  opacity: 1;
}
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  top: 0;
  left: 0;
}
.toggle-button {
  outline: none;
  border: none;
  background: none;
}
.services {
  position: relative;
  cursor: pointer;
  display: inline-block;
  transition: 0.3s;
}

/* sidebar code */
.navbar-links .services:hover > .hovering-navbar {
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  opacity: 1;
}
.link-item-sub {
  font-size: 16px;
  /* color: var(--black); */
  width: max-content;
  font-weight: 600;
  margin: 0px 10px;
  letter-spacing: -0.3px;
}
.navbar-links .services .hovering-navbar {
  position: absolute;
  border: 1px solid var(--yellow800);
  padding: 10px 10px;
  border-radius: 5px;
  width: 188px;
  left: -40px;
  top: 40px;
  opacity: 0;
  /* background: rgb(41, 41, 41); */
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-50 {
  margin-bottom: 30px;
}

/* Additional CSS for the second dropdown */
.hovering-navbar .sub-dropdown {
  display: flex !important;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 100%;
  border: 1px solid var(--yellow800);
  border-radius: 5px;
  padding: 10px 4px;
  margin-left: 2px;
  width: 180px;
  /* background: rgb(41, 41, 41); */
}

.hovering-navbar .sub-dropdown.visible {
  display: block;
}

/* sidebar code */
.navbar-links .services:hover > .hovering-nav {
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  opacity: 1;
}
.navbar-links-mobile {
  display: none;
}
.navbar-links .services .hovering-nav {
  position: absolute;
  border: 1px solid var(--yellow800);
  padding: 10px 4px;
  border-radius: 5px;
  left: -40px;
  top: 40px;
  opacity: 0;
}
.home-hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
}
.hero-section-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.hero-section-heading {
  margin-top: 25vh;
  position: relative;
  z-index: 3;
  font-size: 60px;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  letter-spacing: -1.5px;
  font-weight: 700;
  text-align: left;
  transform: translateX(-5px);
  opacity: 0;
}
.hero-section-description {
  /* font-family: "Roboto Mono", monospace !important;
  margin-top: 10px;
  position: relative;
  z-index: 3;
  font-size: 46px;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  letter-spacing: -0.5px;
  font-weight: 400;
  text-align: left;
  transform: translateX(-5px);
  opacity: 0; */
  position: relative;
  z-index: 3;
  font-size: 60px;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  letter-spacing: -1.5px;
  font-weight: 700;
  text-align: left;
  transform: translateX(-5px);
  opacity: 0;
}
.hero-dot {
  color: var(--yellow800);
  margin-left: 10px;
  margin-top: -38px;
  font-size: 80px;
}
.hero-align {
  display: flex;
  align-items: center;
}
.line {
  border-top: 2px solid var(--yellow800);
  width: 50%;
}
.hero-section-img {
  position: absolute;
  z-index: 2;
  width: 100%;
  transform: translateY(100px);
  opacity: 0;
}
.hero-section-circle {
  position: absolute;
  z-index: 1;
  width: 100%;
  transform: translateY(100px);
  opacity: 0;
}

.blog-card-section-header {
  align-items: baseline;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.blog-card-section-header-title {
  align-items: baseline;
  display: flex;
  flex-grow: 1;
  gap: 0.5rem;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.42;
}
.blog-card-section-header-title:after {
  background-color: var(--yellow900);
  border: none;
  content: "";
  display: block;
  flex-grow: 1;
  height: 2px;
}

.blog-post-horizontal-card {
  display: grid;
  grid-template-columns: 152px 1fr;
  grid-template-rows: 90px;
  gap: 0.5rem;
}

.-two-col.blog-post-list {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
  padding-left: 0px;
}
.blog-post-list .blog-post-list-item {
  display: flex;
  flex-direction: column;
  z-index: 1000;
}
.blogs-wrapper {
  display: grid;
}
.slides {
  padding: 0px 49px !important;
  /* overflow-x: hidden !important; */
  margin-bottom: 50px !important;
  /* overflow-y: visible !important; */
}
.priorty-blog-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--yellow900);
  border-radius: 10px;
  cursor: pointer;
}
.blog-priorty-image {
  height: 16rem;
  margin-bottom: 0.5rem;
  max-height: 100%;
  width: 100%;
}
.text-editer {
  margin-bottom: 50px;
}

.blog-priorty-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
}
.blog-priorty-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
}
.blog-post-featured-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-inline-start: 0px !important;
  margin-bottom: 0px;
}
.mt-50 {
  margin-top: 50px !important;
}
.blog-priorty-title {
  --cl-anchor-font-weight: inherit;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.57;
  padding-bottom: 0.5rem !important;
}

.blog-post-card-featured-image {
  height: 10rem;
  margin-bottom: 0.5rem;
  max-height: 100%;
  width: 100%;
}

.blog-post-card-featured-image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.blog-post-card-body {
  border-bottom: 2px solid #dbe4ed;
  flex-direction: column;
  gap: 0.5rem;
}
.blog-post-card-title {
  --cl-anchor-font-weight: inherit;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.57;
  padding-bottom: 0.5rem !important;
}
.blog-post-card-footer {
  display: flex;
  justify-content: space-between;
}
.blog-post-card-author {
  color: #516f90;
  margin: 0;
  padding-bottom: 0.5rem;
}
.blog-post-card-date {
  color: #516f90;
  margin: 0;
  padding-bottom: 0.5rem;
}
.priorty-blog-post-card-author {
  margin: 0;
  padding-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 600;
}
.priorty-blog-post-card-date {
  margin: 0;
  padding-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 600;
}
.blog-post-list {
  display: grid;
  grid-gap: 1.5rem 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-left: 0px;
}
.blog-post-list-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.blog-post-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: auto;
}
.more-like-this {
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  margin: 30px 0px !important;
}
.blog-post-card-featured-image {
  height: 10rem;
  margin-bottom: 0.5rem;
  max-height: 100%;
}
.blog-post-card-featured-image img {
  max-height: 100%;
}
.blog-name-wrapper {
  display: flex;
  gap: 10px;
  border-top: 3px solid #d1d1d1;
  border-bottom: 3px solid #d1d1d1;
  padding: 10px 0px;
  margin-bottom: 25px;
  justify-content: space-between;
}
.avatar-wrapper {
  border-radius: 100%;
  background: #d1d1d1;
  width: 50px;
  height: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-name {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.blog-title {
  font-size: 35px;
  letter-spacing: 2px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0px;
  cursor: pointer;
  margin-bottom: 20px !important;
  margin-top: 30px !important;
}
.blog-author {
  font-size: 35px;
  color: var(--yellow900);
  letter-spacing: -1px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0px;
  cursor: pointer;
  text-decoration: underline;
}
.blog-detail-img {
  width: 100%;
  height: 400px;
  margin: 20px 0px;
}
.social-share-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.ql-editor {
  padding: 0px !important;
}
.ql-editor img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.swiper-button-prev {
  left: -100px;
}
.swiper-button-next {
  right: -100px;
}
.hero-section-btn {
  position: relative;
  z-index: 3;
  margin-top: 60px;
}
.experience-section {
  position: relative;
}
.experience-height {
  height: 600px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.experience-box {
  padding: 57px 35px;
  width: 100%;
  box-shadow: 0px 0px 50px 0px rgba(43, 43, 43, 0.16);
  -webkit-box-shadow: 0px 0px 50px 0px rgba(43, 43, 43, 0.16);
  -moz-box-shadow: 0px 0px 50px 0px rgba(43, 43, 43, 0.16);
  margin-bottom: 15px;
  position: relative;
  height: 250px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.experience-box .icon-logo {
  position: absolute;
  top: 10px;
  left: 10px;
}
.experience-box .experience-number {
  font-size: 50px;
  letter-spacing: -1.5px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
}
.experience-box .experience-term {
  font-size: 18px;
  color: var(--yellow800);
  font-weight: 600;
  text-align: left;
}
.experience-text {
  font-size: 16px;
  font-family: "Roboto Mono", monospace;
  font-weight: 300;
  letter-spacing: -0.4px;
  margin-bottom: 0px;
}
.experience-img {
  height: 600px;
  width: 100%;
}
.projects-bg {
  background-image: url("https://cdn.dastkaar.studio/website-images/backgrounds/projects-bg.webp");
}
.commerical-projects {
  padding-top: 60px;
}
.projects-bg-content {
  background-image: url("/public/backgrounds/background-lines-2.svg");
  background-size: auto;
  background-position: center center;
  background-repeat: repeat-y;
}
.projects-bg-content-2 {
  background-size: cover;
  background-image: url("/public/backgrounds/background-lines-2.svg");
  background-position: center center;
  background-repeat: repeat-y;
}
@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1366px, 0, 0);
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.minor-heading {
  font-weight: 600;
  font-size: 60px;
  margin-bottom: 0px;
  text-align: center;
  letter-spacing: -1.5px;
  /* color: var(--black); */
  position: relative;
  width: max-content;
  position: absolute;
  bottom: 50px;
}
.minor-heading-2 {
  font-weight: 600;
  font-size: 60px;
  margin-bottom: 0px;
  text-align: center;
  letter-spacing: -1.5px;
  width: max-content;
  position: relative;
  bottom: 50px;
}
.minor-description {
  font-weight: 300;
  /* color: var(--white); */
  font-family: "Roboto Mono", monospace;
  font-size: 18px;
  margin-bottom: 0px;
  text-align: center;
  position: relative;
  width: max-content;
  position: absolute;
  top: 40px;
}
.minor-description-2 {
  font-weight: 300;
  font-family: "Roboto Mono", monospace;
  font-size: 18px;
  margin-bottom: 0px;
  text-align: center;
}
.minor-description-3 {
  font-weight: 300;
  font-family: "Roboto Mono", monospace;
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 20px;
}
.minor-description-4 {
  font-weight: 300;
  font-family: "Roboto Mono", monospace;
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 10px;
}
.ul-list {
  list-style-type: none;
  padding-left: 25px;
}
.minor-heading-69 {
  font-weight: 300;
  /* font-family: "Roboto Mono", monospace; */
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 600;
}
.estimate-picture {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.estimate-image-container {
  border: 1px solid var(--blackLight);
  transition: 0.3s;
  height: 250px;
  overflow: hidden;
}
.estimate-name-senior {
  /* color: var(--white); */
  font-size: 30px;
  letter-spacing: -0.4px;
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 20px;
  text-align: left;
}
.catgories-main {
  padding: 0px 10px;
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #e8ae1b;
  padding: 20px 0px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.catgeory-list {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  gap: 10px;
}
.category-logo {
  display: flex;
  gap: 5px;
  align-items: center;
}
.catgeory-box {
  height: 40px;
  width: 100%;
  background-color: #e8ae1b;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px 10px;
  min-width: 190px;
  cursor: pointer;
}
.upload-plan {
  height: 40px;
  background-color: #e8ae1b;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px 10px;
  width: 150px;
  cursor: pointer;
  margin-top: 30px;
}
.catgeory-box:hover {
  background-color: #e8b940;
}
.list-main {
  margin-top: 20px;
}
.list-text {
  font-weight: 300;
  font-family: "Roboto Mono", monospace;
  font-size: 18px;
  margin-bottom: 5px;
}
.lines-top {
  position: absolute;
  top: -5px;
  left: -15px;
}
.lines-bottom {
  position: absolute;
  bottom: -5px;
  right: -15px;
}
.projects-box {
  border: 1px solid var(--yellow900);
  padding: 16px;
  margin-bottom: 40px;
  transition: 0.3s;
}
.projects-box:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.projects-box .projects-img-box {
  position: relative;
}
.projects-box .project-circle-box {
  position: absolute;
  bottom: -30px;
  right: 20px;
  display: flex;
  opacity: 0;
  transform: translateY(50px);
}
.projects-box .project-circle-box .project-circle {
  height: 60px;
  width: 60px;
  border: 2px solid white;
  border-radius: 50%;
  margin-left: -30px;
  box-shadow: 5px 10px 5px rgb(0 0 0 / 5%);
}
.projects-box .project-name {
  font-size: 30px;
  letter-spacing: -1.5px;
  font-weight: 600;
  margin-top: 35px;
  text-align: left;
  margin-bottom: 10px;
  width: max-content;
  max-width: 250px;
}
.estimate-heading {
  font-size: 30px;
  letter-spacing: -1.5px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
  line-height: 40px;
}
.fix-project-box {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.projects-box .project-img-container {
  overflow: hidden;
  border: 1px solid var(--yellow900);
  min-height: 240px;
}
.projects-box .project-img-container .project-img,
.projects-box .project-img-container .project-img-2 {
  transform: scale(1.35);
  width: 100%;
}
.project-name-span {
  height: 1px;
  width: 100%;
  opacity: 1;
}
.commercial-span {
  height: 1px;
  width: 100%;
  opacity: 1;
}
.projects-box .project-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.projects-box .project-info {
  font-size: 14px;
  font-weight: 300;
  font-family: "Roboto Mono", monospace;
  /* letter-spacing: -1px; */
  margin-bottom: 0px;
  text-align: left;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.projects-box .project-link {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--yellow900);
  background-color: white;
  transition: 0.3s;
  border-radius: 50%;
}
.projects-box .project-link:hover {
  background-color: #fff4d9;
}
.services-bg {
  background-image: url("https://cdn.dastkaar.studio/website-images/backgrounds/services-bg.webp");
}
.services-bg-detail {
  height: 100vh;
  width: 100%;
  border-radius: 50px;
  background-size: cover;
  background-repeat: no-repeat;
}
.services-text-area {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0px 40px 20vh 30px;
}
.services-heading {
  font-size: 6rem;
  margin-bottom: 0px;
  text-align: center;
}
.services-description {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0px;
  text-align: center;
}
.box {
  height: 40px;
  width: 40px;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.a {
  background-color: red;
}
.b {
  background-color: blue;
}
.c {
  background-color: purple;
}
.cutie {
  overflow-x: hidden;
}
.empty-section {
  height: 100vh;
  background-color: #c9981a;
}
.team-bg {
  /* background-color: #0d0d0d; */
  transition: 0.3s;
  z-index: 100;
}
.dee-image {
  position: absolute;
  right: -65%;
  top: -100px;
  z-index: -1;
}
.dee-image-2 {
  position: absolute;
  left: -15%;
  bottom: -10%;
  z-index: -1;
}
.team-heading-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.team-box-padding {
  padding-top: 60px;
  align-items: center;
  justify-content: center;
}
.team-box {
  border: 1px solid var(--yellow900);
  /* background-color: var(--blackLight); */
  padding: 16px;
  margin-bottom: 40px;
  transition: transform 0.1s ease-in-out;
  margin-bottom: 30px;
  cursor: pointer;
}
.download-button {
  display: flex;
  justify-content: center;
}
.team-box:hover {
  /* background-color: #1c1b1b; */
  transform: scale(1.05);
  cursor: pointer;
}
.senior-box-margin {
  margin-bottom: 80px;
}
.team-box .team-image-container {
  border: 1px solid var(--blackLight);
  transition: 0.3s;
  height: 750px;
  overflow: hidden;
}
.team-box .sm-team-container {
  height: 450px;
}
.team-box:hover .team-image-container {
  border: 1px solid var(--yellow900);
}
.team-box .team-image-container .picture {
  width: 100%;
}
.team-box .name-senior {
  color: var(--white);
  font-size: 30px;
  letter-spacing: -0.4px;
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 35px;
  text-align: left;
}
.team-box .name-not-senior {
  color: var(--white);
  font-size: 24px;
  letter-spacing: -0.4px;
  margin-bottom: 6px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
}
.team-box .senior-role {
  font-size: 18px;
  text-align: left;
  margin-bottom: 0px;
  font-weight: 200;
  /* color: var(--white); */
}
.team-box .role {
  font-size: 16px;
  text-align: center;
  margin-bottom: 0px;
  font-weight: 200;
  color: var(--white);
}
.team-box .link {
  background-color: var(--white);
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--yellow900);
}
.moving-bg {
  background-color: var(--grayLight);
  padding: 50px 0px;
  background-repeat: repeat-x;
  animation: slide 100s linear infinite;
  height: 170px;
  width: 4098px;
  position: relative;
}
.contact-bg {
  background-image: url("https://cdn.dastkaar.studio/website-images/backgrounds/contact-bg.webp");
}
.process-bg {
  background-image: url("https://cdn.dastkaar.studio/website-images/backgrounds/process-bg.webp");
}
.contact-input {
  margin-bottom: 30px;
}
.contact-input .label-class {
  font-size: 18px;
  letter-spacing: -0.3px;
  font-weight: 300;
  margin-bottom: 15px;
  outline: none;
  width: 100%;
  text-align: left;
}
.contact-input .input-box {
  border: 1px solid var(--gray);
  padding: 25px 22px;
  font-size: 20px;
  letter-spacing: -0.3px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0px;
  outline: none;
  width: 100%;
  border-radius: 5px;
}
.contact-input .talk-btn {
  padding: 15px 23px;
  text-align: center;
  font-size: 16px;
  letter-spacing: -0.4px;
  font-weight: 600;
  width: max-content;
  display: flex;
  align-items: center;
  transition: 0.3s;
}
.contact-input .talk-btn .talk-btn-img {
  margin-left: 13px;
  transition: 0.3s;
}
.contact-input .talk-btn:hover .talk-btn-img {
  margin-left: 20px;
}
.contact-input .talk-btn:hover {
  cursor: pointer;
  /* background-color: var(--white); */
}
.overlay-message {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.message-loader {
  border: 4px solid var(--gray);
  border-radius: 50%;
  border-top: 4px solid var(--yellow900);
  width: 40px;
  height: 40px;
  -webkit-animation: spin-message 1s linear infinite; /* Safari */
  animation: spin-message 1s linear infinite;
}
.message-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.message-box .message-text {
  font-family: "Roboto Mono", monospace;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
  text-align: center;
}

/* Safari */
@-webkit-keyframes spin-message {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin-message {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.project-page-bg {
  background-image: url("https://cdn.dastkaar.studio/website-images/backgrounds/project-page-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: relative;
  background-position: center;
}
.projects-hero-heading {
  font-size: 60px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
  letter-spacing: -0.8px;
  position: relative;
  z-index: 2;
  clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
}
.lettering .welcome {
  visibility: hidden;
  opacity: 0;
}
#project-stroke {
  position: absolute;
  top: -50px;
  left: 40px;
  z-index: 1;
  width: 100%;
}
.svg-img {
  opacity: 0;
}
.projects-heading-main {
  /* -webkit-text-stroke: 1px #1c1b1b; */
  color: transparent;
  font-size: 7rem;
  text-align: center;
  margin-bottom: 50px;
}
/* .residential-heading,
.commerical-heading {
  transform: translateX(0px);
} */
.services-btn {
  margin-top: 30px;
}
.services-miniheading {
  font-size: 24px;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 0px;
  text-align: center;
}
.transform-90 {
  transform: rotate(-90deg);
}
.testing-service {
  height: 100vh;
}
.project-detail-banner {
  padding: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-detail-heading {
  font-size: 60px;
  font-weight: bold;
  letter-spacing: -4px;
  margin-bottom: 0px;
  line-height: 80px;
}
.project-detail-paragraph {
  margin-top: 20px;
  font-size: 16px;
  font-weight: lighter;
  letter-spacing: -0.6px;
  margin-bottom: 0px;
  line-height: 21px;
}
.project-detail-margin {
  margin-top: 50px;
}
.project-detail-margin-2 {
  margin-top: 35px;
}
.project-desc {
  display: flex;
  align-items: center;
}

.project-desc .project-dots {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #e8ae1b;
  border: 0px solid #707070;
}
.project-desc .project-attributes-1 {
  margin-left: 5px;
  font-size: 16px;
  font-weight: lighter;
  margin-bottom: 0px;
}
.project-attributes-2 {
  margin-top: 15px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.4px;
}
.detail-background-img {
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 450px;
  position: relative;
  z-index: 2;
}
/* .pd-bg-1 {
  background-image: url("/public/backgrounds/project-detail-img-bg.png");
}
.pd-bg-2 {
  background-image: url("/public/backgrounds/project-detail-img-bg-2.png");
} */
.project-detail-hero {
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-detail-hero img {
  width: 100%;
}
.details-row {
  margin-bottom: -72px;
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: -1;
}
.details-heading {
  margin-bottom: 0px;
  margin-left: 0px;
  opacity: 10%;
  font-weight: 800;
  width: auto;
  font-size: 167px;
  letter-spacing: -14.04px;
}
.project-description-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-description-section .description-1 {
  font-size: 24px;
  font-weight: lighter;
  letter-spacing: -0.4px;
  margin: 0px;
  line-height: 32px;
}
.pd-description-img {
  width: 100%;
  margin: 5px 0px;
}
.pd-img-grid {
  padding-top: 120px;
  padding-bottom: 0px;
}
.pd-img-container {
  padding: 15px;
  border: 1px solid var(--yellow800);
  overflow: hidden;
}
.grid-img-1 {
  width: 100%;
}
.client-quote .quote-left {
  position: absolute;
  top: 0px;
}
.client-quote .quote-right {
  position: absolute;
  bottom: 0px;
}
.estimate-img {
  width: 100%;
  height: 300px;
}
.client-name {
  margin-top: 40px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
.client-country {
  font-size: 18px;
  font-weight: 300;
  opacity: 0.6;
  text-align: center;
  margin-bottom: 0px;
}
.client-review {
  line-height: 24px;
  font-size: 18px;
  font-weight: lighter;
  letter-spacing: -1.04px;
  margin-bottom: 0px;
  text-align: center;
}

/* /
/
/
/
/
/ */
.overscroll-img {
  overflow-y: hidden;
}
.comparisonSection {
  position: relative;
  padding-bottom: 56.25%; /* to maintain aspect ratio (responsive!) */
}
.comparisonImage {
  width: 100%;
  height: 100%;
}

.thirdImage,
.afterImage {
  position: absolute;
  overflow: hidden;
  top: 0;
  transform: translate(0px, 100%);
}

.thirdImage img,
.afterImage img {
  transform: translate(0px, -100%);
}

.comparisonImage img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.footer-main {
  background-color: var(--black);
  background-image: url("/public/backgrounds/footer-bg.svg");
  background-attachment: fixed;
  background-size: cover;
  padding-bottom: 40px;
}
.footer-bg {
  background-image: url("/public/backgrounds/dastkaar-logo-bg.svg");
  background-position: 106% 170%;
  background-repeat: no-repeat;
}
.services-design-bg {
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.interior-design-bg {
  background-image: url("https://cdn.dastkaar.studio/website-images/backgrounds/interior-design-bg-2.webp");
  background-size: cover;
  height: 100vh;
}
.exterior-design-bg {
  background-image: url("https://cdn.dastkaar.studio/website-images/backgrounds/exterior-desgin-bg.webp");
  background-size: cover;
}
.three-d-design-bg {
  background-image: url("https://cdn.dastkaar.studio/website-images/backgrounds/3d-bg.webp");
  background-size: cover;
}
.service-heading {
  position: relative;
  z-index: 3;
  font-size: 60px;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  letter-spacing: -1.5px;
  font-weight: 700;
  text-align: center;
  transform: translateX(-5px);
  opacity: 0;
  margin-bottom: 0px;
}
.contact-heading {
  position: relative;
  z-index: 3;
  font-size: 60px;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  letter-spacing: -1.5px;
  font-weight: 700;
  text-align: left;
  transform: translateX(-5px);
  opacity: 0;
  margin-bottom: 0px;
  text-align: center;
}
.blog-heading {
  letter-spacing: -1.5px;
  font-weight: 700;
  text-align: left;
  opacity: 0;
  margin-bottom: 50px !important;
  text-align: center;
}

.service-description {
  position: relative;
  font-family: "Roboto Mono", monospace;
  z-index: 3;
  font-size: 16px;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  letter-spacing: -1.5px;
  font-weight: 300;
  text-align: center;
  transform: translateX(-5px);
  opacity: 0;
  padding: 0px 5px;
  margin-bottom: 20px;
  width: max-content;
}
.interior-process {
  display: flex;
  align-items: center;
  justify-content: center;
}
.interior-process-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.interior-line {
  height: 1px;
  background-color: var(--yellow900);
  width: 100%;
  position: absolute;
}
.bottom-0 {
  bottom: 0px;
  z-index: 4;
}
.top-0 {
  top: 0px;
  z-index: 4;
}
/* .bg-white {
  background-color: white !important;
} */
.interior-img-container {
  border: 1px solid var(--yellow900);
  padding: 10px;
  /* background-color: white; */
  width: max-content;
}
.interior-img {
  width: 100%;
  border: 1px solid var(--yellow900);
}
.img-a {
  position: absolute;
  top: 15%;
  transform: translateY(-40px);
  right: 0px;
  z-index: 2;
}
.img-b {
  position: absolute;
  bottom: 15%;
  left: 0px;
  transform: translateY(-20px);
  z-index: 2;
}
.interior-software {
  font-size: 16px;
  color: var(--yellow900);
  font-weight: 700;
  text-align: left;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.interior-step-name {
  position: relative;
  z-index: 3;
  font-size: 40px;
  letter-spacing: -1.5px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 10px;
}
.interior-step-description {
  position: relative;
  z-index: 3;
  font-size: 18px;
  letter-spacing: -0.5px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0px;
}
.straight-line {
  height: 100%;
  width: 1px;
  background-color: var(--yellow900);
}
.process-lines-height {
  height: 250px;
}
.left-line {
  position: absolute;
  left: 0px;
}
.right-line {
  position: absolute;
  right: 0px;
}
.right-line-circle {
  position: absolute;
  z-index: 2;
  right: -8px;
}
.left-line-circle {
  position: absolute;
  z-index: 2;
  left: -8px;
}
.circle-sm {
  /* background-color: white; */
  height: 16px;
  opacity: 1;
  width: 16px;
  border: 3px solid var(--yellow900);
  border-radius: 50%;
  transform: translateY(-10px);
}
.zindex-3 {
  z-index: 3;
  position: relative;
}
.footer-heading {
  font-size: 35px;
  color: white;
  letter-spacing: -1.5px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0px;
}
.heart-1 {
  bottom: 10%;
  left: 0;
  animation: 4s cubic-bezier(0.535, 0.15, 0.425, 1) home-sponsors-heart-1
    infinite;
}
.heart-2 {
  bottom: 10%;
  left: 0;
  animation: 5s cubic-bezier(0.535, 0.15, 0.425, 1) -1s home-sponsors-heart-2 infinite;
}
@keyframes home-sponsors-heart-1 {
  0% {
    opacity: 0;
    transform: scale3d(0.7, 0.7, 0.7) translate3d(0, 0, 0) rotate(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale3d(1, 1, 1) translate3d(-25%, -160%, 0) rotate(-25deg);
  }
}
@keyframes home-sponsors-heart-2 {
  0% {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 0, 0) rotate(0);
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0;
    transform: scale3d(0.75, 0.75, 0.75) translate3d(-125%, -100%, 0)
      rotate(-35deg);
  }
}
.footer-links-heading {
  color: var(--white);
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: left;
  opacity: 1;
}
.footer-sm-links {
  color: var(--white);
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 400;
  text-align: left;
  transition: 0.3s;
  opacity: 0.7;
  border-bottom: 1px solid transparent;
  width: max-content;
}
.footer-sm-links:hover {
  opacity: 1;
  border-bottom: 1px solid var(--yellow900);
}
.footer-description {
  color: var(--white);
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: 300;
  text-align: left;
  width: 60%;
}
.remove-padding-desktop {
  padding-left: 0px;
  padding-right: 0px;
}
.about-heading-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aboutpage-bg {
  background-image: url("https://cdn.dastkaar.studio/website-images/backgrounds/about-bg.svg");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.about-heading {
  font-size: 60px;
  margin-top: 120px;
  font-weight: bold;
  line-height: 80px;
  letter-spacing: -2.04px;
  text-align: center;
  clip-path: polygon(24% 98%, 74% 98%, 100% 100%, 0% 100%);
}
.map-heading {
  font-size: 80px;
  font-weight: bold;
  line-height: 80px;
  letter-spacing: -2.04px;
  text-align: center;
  position: relative;
  z-index: 4;
}
.about-img {
  width: 70%;
  margin-top: -1px;
}
.about-para {
  font-family: "Roboto Mono", monospace;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 0px;
  clip-path: polygon(0 0, 100% 0, 100% 1%, 0 0);
}
.map-adjustments {
  position: absolute;
  max-width: 100%;
  top: 100px;
}
.ping-container {
  position: absolute;
  top: calc(100vh - 42vh);
  right: calc(100vh - 63vh);
}
.ping-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ping-loader .ping {
  -webkit-animation: ping 1.8s ease-in-out infinite both;
  animation: ping 1.8s ease-in-out infinite both;
}
/* .pingg {
  -webkit-animation: ping 1.8s ease-in-out infinite both;
  animation: ping 1.8s ease-in-out infinite both;
} */

/* .pingg:hover {
  transform: scale(2);
} */
.ping-loader2 {
  background-color: #f7e4b9;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.ping-loader .circle-of-ping2 {
  width: 20vh;
  height: 20vh;
}
.ping-loader .circle-of-ping {
  position: absolute;
  width: 10vh;
  height: 10vh;
  border: 1px solid var(--yellow800);
}
.ping-loader .circle-of-ping,
.ping-loader .circle-of-ping2 {
  background-color: #f7e4b9;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.ping-loader .circle-of-ping .ping-img {
  width: 75%;
}
@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
@media (min-width: 421px) and (max-width: 1080px) {
  .loader .circle-of-loader img {
    width: 40%;
  }
}
@media (min-width: 250px) and (max-width: 420px) {
  .loader .circle-of-loader img {
    width: 50%;
  }
}
.trademark {
  padding: 40px 0px 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trademark .sinecure {
  font-family: "Roboto Mono", monospace;
  font-size: 16px;
  font-weight: 300;
  color: var(--white);
  text-align: center;
  margin-bottom: 0px;
}
.trademark .sinecure-text {
  border-bottom: 1px solid var(--yellow900);
}
.bim-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bim-heading-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.bim-heading {
  position: relative;
  z-index: 3;
  font-size: 60px;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  letter-spacing: -1.5px;
  font-weight: 700;
  text-align: left;
  transform: translateX(-5px);
  opacity: 0;
  margin-bottom: 0px;
}
.bim-banner-img {
  transform: translatey(0px);
  opacity: 0;
  animation: float 6s ease-in-out infinite;
}
.bim-text {
  font-family: "Roboto Mono", monospace;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 0px;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
}
.to-this-text {
  font-size: 7rem;
  letter-spacing: -1.5px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  opacity: 0;
  transform: translateY(100px);
}
.from-this-text {
  position: relative;
  z-index: 4;
  font-size: 5rem;
  letter-spacing: -1.5px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0px;
  transform: translateY(-100px);
  opacity: 0;
}
.solve-text {
  font-size: 35px;
  letter-spacing: -1.5px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0px;
}
.span-underline {
  border-bottom: 5px solid var(--yellow900);
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
.hide-on-desktop {
  display: none;
}
.hide-on-mobile {
  display: block;
}
.no-internet-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* /
/
/
/
/
/
/
/
/ */

@media (max-width: 1000px) {
  .blog-post-list {
    display: grid;
    grid-gap: 1.5rem 1rem;
    grid-template-columns: 1fr 1fr;
    padding-left: 0px;
  }
}

@media (max-width: 600px) {
  .blog-post-list {
    display: grid;
    grid-gap: 1.5rem 1rem;
    grid-template-columns:1fr;
    padding-left: 0px;
  }
}


@media (max-width: 460px) {

  .catgories-main {
    margin-top: 30px;
  }
  
  .minor-description-3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .list-main {
    margin-top: 10px;
  }
  .mt-50 {
    margin-top: 30px;
  }
  .list-text {
    font-size: 16px;
  }
  .ul-list {
    padding-left: 10px;
  }

  .navbar-links-mobile {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-links {
    display: none;
  }
  .section-padding-normal {
    padding: 80px 0px;
  }
  .section-padding-top {
    padding-top: 80px;
  }
  .remove-padding-desktop {
    padding-left: 15px;
    padding-right: 15px;
  }
  .hero-section-center {
    overflow: hidden;
  }
  .home-hero-section {
    height: 100vh;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .line {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .hero-dot {
    margin-left: 10px;
    margin-top: -16px;
    font-size: 40px;
  }
  .hero-section-heading {
    font-size: 40px;
    padding-top: 50px;
  }
  .hero-section-description {
    font-size: 40px;
  }
  .hero-section-circle {
    top: 150px;
  }
  .hero-section-img {
    top: 150px;
  }
  .experience-height {
    height: auto;
  }
  .experience-box {
    height: 180px;
  }
  .experience-img {
    height: auto;
  }
  .experience-box .experience-number {
    font-size: 35px;
  }
  .experience-box .experience-term {
    font-size: 16px;
  }
  .experience-text {
    padding-bottom: 40px;
  }
  .moving-bg {
    height: 120px;
    padding: 40px 0px;
  }
  .projects-box .project-img-container {
    height: 350px;
  }
  .team-box .team-image-container {
    height: 400px;
  }
  .minor-heading,
  .minor-heading-2 {
    font-size: 40px;
    bottom: 35px;
  }
  .projects-heading-main {
    font-size: 3rem;
  }
  .senior-box-margin {
    margin-bottom: 40px;
  }
  .team-box .name-senior {
    font-size: 26px;
    margin-top: 25px;
  }
  .contact-input .label-class {
    font-size: 16px;
  }
  .contact-input .input-box {
    padding: 15px 12px;
    font-size: 16px;
  }
  .footer-description {
    width: 100%;
    font-size: 16px;
    margin-top: -20px;
  }
  .footer-links-heading {
    margin-top: 40px;
  }
  .footer-bg {
    background-position: -166% 98%;
  }
  .projects-hero-heading {
    font-size: 35px;
  }
  #project-stroke {
    position: absolute;
    top: -80px;
    left: 0px;
    z-index: 1;
  }
  .interior-process-box {
    height: 350px;
  }
  .process-lines-height {
    height: 120px;
    position: relative;
  }
  .svg-mobile {
    width: 100%;
  }
  .service-heading {
    font-size: 35px;
  }
  .about-heading {
    font-size: 35px;
    line-height: 110%;
  }
  .map-heading {
    font-size: 35px;
    line-height: 110%;
  }
  .about-heading-area {
    margin-top: 250px;
  }
  .location-margin {
    margin-top: 270px;
  }
  .map-adjustments {
    top: 0px;
  }
  .contact-heading {
    text-align: center;
    font-size: 45px;
  }
  .message-box .message-text {
    font-size: 14px;
  }
  .project-detail-banner {
    padding: 30px;
    flex-direction: column-reverse;
  }
  .project-detail-heading {
    font-size: 35px;
    line-height: 110%;
    letter-spacing: -1px;
  }
  .project-detail-hero {
    padding-left: 0px;
    padding-right: 0px;
  }
  .details-row {
    margin-bottom: -20px;
  }
  .details-heading {
    letter-spacing: -4px;
    font-size: 60px;
  }
  .detail-background-img {
    height: 200px;
  }
  .client-quote .quote-right {
    width: 30px;
    bottom: -10px;
    right: 20px;
  }
  .client-quote .quote-left {
    width: 30px;
    left: 20px;
    top: -30px;
  }
  .client-name {
    font-size: 22px;
  }
  .project-desc-mobile {
    margin-bottom: 30px;
  }
  .project-detail-margin-2 {
    margin-top: 0px;
  }
  .bim-heading-center {
    flex-direction: column-reverse;
  }
  .bim-heading {
    font-size: 35px;
    margin-top: 30px;
  }
  .from-this-text {
    font-size: 2rem;
  }
  .to-this-text {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  .hide-on-mobile {
    display: none;
  }
  .hide-on-desktop {
    display: block;
  }
  .aboutpage-bg {
    background-image: url("https://cdn.dastkaar.studio/website-images/backgrounds/about-bg-mobile.svg");
  }
}
